import { IMAGE_RATIO } from 'components/PlansButtonsContainer';
import { AppContext, AppContextType } from 'context';
import { motion } from 'framer-motion';
import { useContext, useMemo } from 'react';
import { Badge } from 'react-bootstrap';
import { AvailabilityDataType } from 'types';
import { Marker, buttonsContainerPositionType } from 'types/configuration';
import { isEmpty } from 'utils/array';

export type PlanButtonPropsType = {
  buttonText: string | Record<string, string>;
  delay: number;
  linkedImage: string;
  setCurrentImage: React.Dispatch<React.SetStateAction<string>>;
  setCurrentMarkers: React.Dispatch<React.SetStateAction<Marker[]>>;
  markers: Marker[];
  setZoomedMenu: React.Dispatch<React.SetStateAction<boolean>>;
  zoomedMenu: boolean;
  setCurrentPlan: React.Dispatch<React.SetStateAction<number>>;
  planNumber: number;
  currentPlan: number;
  width: number;
  buttonsContainerPosition: buttonsContainerPositionType;
  hiddenUnits: string[];
  areFiltersActive: boolean;
  availabilityData: AvailabilityDataType;
};

export const PlanButton: React.FC<PlanButtonPropsType> = ({
  buttonText,
  delay,
  linkedImage,
  setCurrentImage,
  setCurrentMarkers,
  markers,
  setZoomedMenu,
  zoomedMenu,
  setCurrentPlan,
  planNumber,
  currentPlan,
  width,
  buttonsContainerPosition,
  hiddenUnits,
  areFiltersActive,
  availabilityData,
}) => {
  const { useStyles, tConfig } = useContext<AppContextType>(AppContext);

  const classes = useStyles();
  const multiplier = width / IMAGE_RATIO / 100;

  const planButtonStyle = zoomedMenu
    ? {
        paddingTop:
          multiplier * parseFloat(buttonsContainerPosition.normal.paddingTop),
        fontSize:
          multiplier * parseFloat(buttonsContainerPosition.normal.fontSize),
      }
    : {
        paddingTop:
          multiplier *
          parseFloat(buttonsContainerPosition.minimized.paddingTop),
        fontSize:
          multiplier * parseFloat(buttonsContainerPosition.minimized.fontSize),
      };

  const filteredUnitsCounter = useMemo(
    () =>
      markers.length -
      markers.reduce((acc, marker) => {
        return hiddenUnits.includes(marker.title) ||
          !availabilityData.units.some((u) => u.name === marker.title)
          ? acc + 1
          : acc;
      }, 0),
    [availabilityData.units, hiddenUnits, markers],
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity:
          (markers.every(
            (m) =>
              hiddenUnits.includes(m.title) ||
              // If the unit is not present between availabilites
              !availabilityData.units.some((u) => u.name === m.title),
          ) ||
            isEmpty(markers)) &&
          areFiltersActive
            ? 0.4
            : 1,
      }}
      transition={{
        duration: 2,
        delay: delay,
        ease: [0, 0.71, 0.2, 1.01],
      }}
      onClick={() => {
        setCurrentImage(linkedImage);
        setCurrentMarkers(markers!);
        setZoomedMenu(false);
        setCurrentPlan(planNumber);
      }}
    >
      <motion.div whileTap={{ scale: 0.95 }}>
        <div
          className={zoomedMenu ? classes.planButton : classes.planButtonMin}
          onClick={() => {
            setCurrentImage(linkedImage);
            setCurrentMarkers(markers!);
          }}
          style={{
            ...planButtonStyle,
            fontWeight: currentPlan === planNumber ? 900 : 400,
          }}
        >
          <div className="d-flex align-items-center position-relative">
            {areFiltersActive && (
              <Badge
                className={`me-2 position-absolute ${classes.filterCounterBadge}`}
                pill
              >
                {filteredUnitsCounter}
              </Badge>
            )}
            {tConfig(buttonText)}
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};
