import * as Constants from 'constants/constants';
import { Styles } from 'jss';

export const BASE_STYLES: Styles = {
  '@global': {
    body: { overflow: 'hidden' },
  },

  nunitoSans: {
    fontFamily: '"Nunito Sans", sans-serif',
    fontOpticalSizing: 'auto',
    fontWeight: 400,
    fontStyle: 'normal',
    fontVariationSettings: { wdth: 100, YTLC: 500 },
  },

  zIndex0: { zIndex: '0' },
  zIndex10: { zIndex: '10' },
  zIndex20: { zIndex: '20' },
  hit: {
    position: 'absolute',
    zIndex: '30',
    height: '40px',
    width: '60px',
    border: 'solid 4px #1b2231',
  },
  hitSuccess: {
    borderColor: Constants.GREEN_COLOR,
  },
  hit01: {
    top: '720px',
    left: '2818px',
  },
  hit02: {
    top: '624px',
    left: '2818px',
  },
  logoTop: {
    left: '5.5%',
    top: '13%',
    width: '40rem',
    zIndex: '0',
  },
  appContainer: { color: '#1b2231' },
  navLink: { color: 'blue' },
  nav: { flexWrap: 'nowrap' },
  background: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: '-10',
  },
  backgroundObject: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
  },

  footer: { zIndex: '100' },
  homeButton: {
    position: 'absolute',
    zIndex: 30,
    cursor: 'pointer',
    userSelect: 'none',
  },
  homeIconImg: { width: '45px', paddingBottom: '10px' },
  mainNavButton: {
    margin: '0 5px',
    cursor: 'pointer',
    userSelect: 'none',
    textAlign: 'center',
    width: '100%',
    maxWidth: '160px',
    marginTop: '28px',
  },
  mainNavButtonInner: {
    color: '#fff',
    margin: 'auto',
    padding: '0 20px 10px 20px',
    borderBottom: '3px solid #6b7a90',

    '@media (max-width: 767px)': {
      padding: '0 0 10px 0!important',
    },
  },

  mainNavButtonImg: {
    display: 'block',
    margin: 'auto',
    '@media (max-width: 767px)': {
      width: '50%',
      height: '35px',
    },
  },
  planButton: {
    color: '#6b7a90',
    textTransform: 'uppercase',
    width: '350px',
    borderBottom: '1px solid #6b7a90',
    userSelect: 'none',
    cursor: 'pointer',
    textAlign: 'initial',
  },
  materialButton: {
    color: '#6b7a90',
    textTransform: 'uppercase',
    paddingTop: '10%',
    borderBottom: '1px solid #6b7a90',
    fontSize: '12px',
    userSelect: 'none',
    cursor: 'pointer',
  },
  materialButtonsContainer: {
    width: '150px',
    margin: 'auto',
  },

  videoButtonsContainer: {
    width: 150,
    margin: 'auto',
    height: 300,
    alignContent: 'center',
  },

  planButtonMin: {
    color: '#6b7a90',
    textTransform: 'uppercase',
    width: '350px',
    borderBottom: '1px solid #6b7a90',
    userSelect: 'none',
    cursor: 'pointer',
    textAlign: 'initial',
  },
  buttonContainer: {
    position: 'absolute',
    zIndex: '30',
    width: '100%',
  },
  planMarker: {
    position: 'absolute',
    backgroundColor: '#fff',
    opacity: 1,
    color: '#000',
    fontSize: '13px',
    borderRadius: '100vw',
    display: 'flex',
    height: '30px',
    width: '50px',
    justifyContent: 'center',
    paddingTop: '3px',
  },
  flipbookButtons: {
    width: 40,
    height: 40,
    borderRadius: '100%',
    backgroundColor: 'rgba(0, 0, 0, .2)',
    margin: 3,
    color: '#6b7a90',
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    opacity: 0.5,
    alignContent: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingTop: '2px',
  },
  unitButtons: {
    width: 45,
    height: 45,
    borderRadius: '100%',
    backgroundColor: 'rgba(0, 0, 0, .2)',
    margin: 3,
    padding: '7px 0!important',
    color: '#6b7a90',
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    position: 'relative',
  },
  flipbookButtonsContainer: {
    width: '80%',
    margin: '25px auto 0 auto',
  },
  flipbookActiveButton: {
    opacity: 1,
  },
  mainLogo: {
    marginTop: '7rem',
  },
  pageTitle: {
    color: 'white',
  },

  homeLogo: {
    width: '50%',
    margin: 'auto',
    left: 0,
    top: '25%',
    right: 0,
    position: 'absolute',
  },

  markersButtonContainer: {
    width: '80%',
    margin: '35px auto 35px',
  },

  mobileSelect: {
    backgroundColor: 'transparent',
    width: '260px',
    border: 'none',
    borderRadius: '0',
    textAlign: 'center',
    borderBottom: '1px solid',
    '&:focus': {
      borderColor: '#6b7a90',
      boxShadow: 'none',
    },
  },

  unavailableImage: {
    height: 250,
    width: 250,
    margin: 'auto',
    display: 'flex',
    alignContent: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
    borderRadius: '3%',
    textAlign: 'center',
  },

  imageNotAvailableText: {
    fontSize: '5.25rem',
  },
  arrowIcon: {
    color: 'white',
    cursor: 'pointer',
  },
  sideMenuIcon: {
    color: 'white',
    fontSize: 30,
    backgroundColor: '#00000070',
    padding: 10,
    borderRadius: '100%',
    cursor: 'pointer',
  },
  activeSideMenuIcon: {
    color: 'black!important',
    backgroundColor: 'white!important',
  },
  initialsText: {
    userSelect: 'none',
    fontWeight: 900,
  },
  loginButton: {
    width: 'auto',
    fontSize: '30px',
    borderRadius: '100%',
    padding: '20px',
    position: 'absolute',
    zIndex: 999,
    cursor: 'pointer',
  },
  qrPopover: {
    width: 150,
    height: 150,
    position: 'absolute',
    backgroundColor: 'white',
    bottom: 85,
    borderRadius: '10px 0 0 10px',
    padding: 10,
    zIndex: 999,

    '& img': {
      maxHeight: '100%',
      maxWidth: '100%',
    },
  },

  initialsButton: {
    textWrap: 'nowrap',
    display: 'flex',
    color: 'white',
    alignItems: 'center',
    backgroundColor: '#00000070',
    borderRadius: 100,
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'relative',
    maxWidth: 90,
    height: 50,
    minWidth: 50,
    margin: '0 7px',
  },

  tabButton: {
    fontSize: '100%',
    padding: 10,
    fontWeight: 900,
    marginBottom: 5,
    border: 'none',
    backgroundColor: '#f4ede3!important',
    color: '#c6a672!important',
    '&.active, &:hover, &:active, &:focus-visible, &:focus': {
      color: '#f4ede3!important',
      backgroundColor: '#c6a672!important',
    },
  },

  statusButton: {
    fontSize: '100%',
    padding: 10,
    fontWeight: 900,
    border: 'none',
    backgroundColor: '#f4ede3',
    color: '#c6a672',
    '&:hover': {
      backgroundColor: '#f4ede3',
      color: '#c6a672',
    },
    '&:active': {
      backgroundColor: '#f4ede3!important',
      color: '#c6a672!important',
    },
  },

  selectionsTable: {
    fontSize: 13,
    width: 'auto',
    '& td': {
      width: 250,
      textAlign: 'left',
      fontFamily: '"Nunito Sans", sans-serif',
      borderColor: '#cecbc7',
    },
    '& th': {
      textAlign: 'left',
    },
    '& td img': {
      height: 150,
      cursor: 'pointer',
      boxShadow: '0px 3px 10px #bcbcbc',
    },
    '& th, & td:first-child': {
      left: 250,
      display: 'block',
      color: '#888888',
      border: 'none',
      zIndex: 2,
      backgroundColor: '#e7e2de',
      boxShadow: '5px -7px 5px #e7e2de',
      '@media screen and (max-width:575px)': {
        width: 150,
      },
    },
    '& tr:first-child td': {
      borderBottom: 'none!important',
      '@media screen and (max-width:575px)': {
        width: '150px!important',
      },
    },
  },
  sectionTitle: {
    paddingTop: '30px!important',
    fontSize: 17,
    width: 250,
    color: '#846a41!important',
  },
  stickyBackground: {
    position: 'fixed',
    zIndex: 1,
    backgroundColor: '#e7e2de!important',
  },
  noSelectedPropertiesWarning: {
    zIndex: 1,
    width: 230,
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    position: 'relative',
    top: '45%',
    color: '#846a41!important',
    '& .btn': {
      backgroundColor: '#846a41!important',
      borderColor: '#846a41!important',
    },
  },

  unitBadgeText: {
    backgroundColor: '#615031',
    color: 'white',
  },

  propertyInfoContainerRow: {
    padding: '5px 0',
    position: 'relative',
    '&::after': {
      content: "''",
      position: 'absolute',
      left: 10,
      bottom: 0,
      height: '1px',
      width: '82%',
      borderBottom: '1px solid #eee8dd!important',
    },
  },
  propertyInfoContainerTitle: {
    margin: '50px 0 10px 0',
    fontSize: 25,
    color: '#937c58',
  },
  addFromKeyplanButton: {
    backgroundColor: '#937c58!important',
  },

  addFromKeyplanTableButtonContainer: {
    width: 105,
    height: 150,
    marginTop: 12,
    backgroundColor: '#836a41',
    color: 'white',
    padding: 10,
    boxShadow: '0px 3px 10px #bcbcbc',
    border: '1px solid white',
    cursor: 'pointer',
  },

  addFromKeyplanTableButton: {
    fontSize: 25,
    textAlign: 'center',
    height: '70%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectionsTableStructure: {
    marginLeft: 290,
    '& th, & td:first-child': {
      position: 'sticky',
    },
  },
  selectionsTableStructureResponsive: {
    marginLeft: 290,
    '@media (max-width:1199px)': {
      marginTop: '25%',
      marginLeft: 0,
    },
  },
  spinner: {
    fontSize: '45px',
    textIndent: '-9999em',
    overflow: 'hidden',
    width: '1em',
    height: '1em',
    borderRadius: '50%',
    position: 'relative',
    display: 'inline-block',
  },
  filterCounterBadge: {
    left: -28,
    fontSize: 10,
    fontFamily: '"Nunito Sans", sans-serif',
    backgroundColor: '#846a41!important',
  },

  //RESPONSIVE MEDIA QUERIES
  //XL and XXL Screens
  '@media screen and (min-width: 1200px)': {},

  //Medium & Large Screens
  '@media screen and (min-width: 768px) and (max-width: 1200px)': {
    propertyInfoContainerTitle: {
      fontSize: 20,
    },
    homeIconImg: { width: '30px', paddingBottom: '10px' },
    mainLogo: {
      maxWidth: '60%',
      marginLeft: '-1rem',
      marginTop: '1rem',
      margin: 'auto',
    },
    galleryContainer: { marginTop: '15%' },
    '@global': {
      body: { overflow: 'auto', overflowX: 'hidden' },
    },
  },
  //X-small and Small Screens
  '@media screen and (max-width: 768px)': {
    propertyInfoContainerTitle: {
      fontSize: 20,
    },
    homeIconImg: { width: '25px', paddingBottom: '10px' },
    mainLogo: {
      maxWidth: '80%',
      marginTop: 0,
      marginLeft: 0,
      marginBottom: 0,
    },
    mainNavButton: {
      maxWidth: '110px!important',
      '& span': {
        fontSize: '12px!important',
      },
    },

    galleryContainer: { marginTop: '20%' },
    '@global': {
      body: { overflow: 'auto', overflowX: 'hidden' },
    },
    homeLogo: {
      top: '10%',
      width: '80%',
    },
  },
};
