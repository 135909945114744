import { PlanButton } from 'components/PlanButton';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { AvailabilityDataType } from 'types';
import { Marker, buttonsContainerPositionType } from 'types/configuration';

export const IMAGE_RATIO = 1920 / 1080;

export type PlansButtonsContainerProps = {
  setCurrentImage: React.Dispatch<React.SetStateAction<string>>;
  setCurrentMarkers: React.Dispatch<React.SetStateAction<Marker[]>>;
  dataWithAvailabilities: {
    markers: Marker[];
    buttonText: string | Record<string, string>;
    backgroundImage: string;
  }[];
  zoomedMenu: boolean;
  setZoomedMenu: React.Dispatch<React.SetStateAction<boolean>>;
  width: number;
  buttonsContainerPosition: buttonsContainerPositionType;
  hiddenUnits: string[];
  areFiltersActive: boolean;
  availabilityData: AvailabilityDataType;
};

export const PlansButtonsContainer: React.FC<PlansButtonsContainerProps> = ({
  setCurrentImage,
  setCurrentMarkers,
  dataWithAvailabilities,
  zoomedMenu,
  setZoomedMenu,
  width,
  buttonsContainerPosition,
  hiddenUnits,
  areFiltersActive,
  availabilityData,
}) => {
  const localClasses = useLocalStyles({
    buttonsContainerPosition,
    width,
    IMAGE_RATIO,
  });

  const [currentPlan, setCurrentPlan] = useState(-1);

  return (
    <motion.div
      initial={{ top: 100, opacity: 0 }}
      animate={{ top: 0, opacity: 1 }}
      transition={{
        duration: 2,
        ease: [0, 0.71, 0.2, 1.01],
      }}
      key={`keyplanLinks${currentPlan}`}
    >
      <div
        className={
          zoomedMenu
            ? localClasses.buttonContainer
            : localClasses.minButtonContainer
        }
      >
        {dataWithAvailabilities.map((pB, idx) => (
          <PlanButton
            buttonText={pB.buttonText}
            delay={idx / 4}
            key={idx}
            linkedImage={pB.backgroundImage}
            setCurrentImage={setCurrentImage}
            setCurrentMarkers={setCurrentMarkers}
            markers={pB.markers}
            setZoomedMenu={setZoomedMenu}
            zoomedMenu={zoomedMenu}
            setCurrentPlan={setCurrentPlan}
            planNumber={idx}
            currentPlan={currentPlan}
            width={width}
            buttonsContainerPosition={buttonsContainerPosition}
            hiddenUnits={hiddenUnits}
            areFiltersActive={areFiltersActive}
            availabilityData={availabilityData}
          />
        ))}
      </div>
    </motion.div>
  );
};

const useLocalStyles = createUseStyles({
  buttonContainer: ({
    buttonsContainerPosition,
    width,
    IMAGE_RATIO,
  }: {
    buttonsContainerPosition: buttonsContainerPositionType;
    width: number;
    IMAGE_RATIO: number;
  }) => ({
    left: (width / 100) * parseFloat(buttonsContainerPosition.normal.left),
    top:
      (width / IMAGE_RATIO / 100) *
      parseFloat(buttonsContainerPosition.normal.top),
    position: 'absolute',
    zIndex: '30',
    width: '100%',
  }),
  minButtonContainer: ({
    buttonsContainerPosition,
    width,
  }: {
    buttonsContainerPosition: buttonsContainerPositionType;
    width: number;
  }) => ({
    left: (width / 100) * parseFloat(buttonsContainerPosition.minimized.left),
    top:
      (width / IMAGE_RATIO / 100) *
      parseFloat(buttonsContainerPosition.minimized.top),
    position: 'absolute',
    zIndex: '30',
    width: '14%',
  }),
});
