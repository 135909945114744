import { LeftColumn } from 'components/LeftColumn';
import { MobileBottomContent } from 'components/MobileBottomContent';
import * as Constants from 'constants/constants';
import { AppContext, AppContextType } from 'context';
import { motion } from 'framer-motion';
import { MarkerWindow } from 'pages/KeyPlan/GestureWindow';
import { ModalResponsive } from 'pages/KeyPlan/components/ModalResponsive';
import { useKeyplanResponsiveState } from 'pages/KeyPlan/hooks/useKeyplanResponsiveState';
import { useKeyPlanState } from 'pages/KeyPlan/hooks/useKeyplanState';
import { AnimatedTd } from 'pages/UserSelection/components/AnimatedTd';
import { UseUserSelectionsPage } from 'pages/UserSelection/hooks/UseUserSelectionsPageState';
import React, { Fragment, useContext, useEffect } from 'react';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { AvailabilityColorsType, Keyplan } from 'types/configuration';
import { OpportunityProductRaw } from 'types/opportunity';
import { isEmpty } from 'utils/array';
import { priceFormatter } from 'utils/priceFormatter';

type UserSelectionsPageType = {
  backgroundImage: string;
  data: Keyplan[];
  availabilityColors: AvailabilityColorsType;
  gestureWindowHeight?: number;
  isFullScreen: boolean;
};

export const UserSelectionsPage: React.FC<UserSelectionsPageType> = ({
  backgroundImage,
  data,
  availabilityColors,
  gestureWindowHeight,
  isFullScreen,
}) => {
  const {
    availabilityData,
    opportunityProductDeletionInProgress,
    setOpportunityProductDeletionInProgress,
  } = useKeyPlanState(backgroundImage, data, availabilityColors);

  const navigate = useNavigate();

  const {
    generalInformationColumns,
    pertinencesFields,
    compositionFields,
    internalStatusFields,
    internalFeaturesFields,
    parkingFields,
    energyRating,
    surfacesFields,
    visitDetails,
    opportunityProductsSortedByStatusAndFeedbacks,
  } = UseUserSelectionsPage();

  const { tDom, t, useStyles, markerWindows, setMarkerWindows } =
    useContext<AppContextType>(AppContext);

  const classes = useStyles();
  const localClasses = useLocalStyles();

  useEffect(() => {
    if (!visitDetails) {
      navigate('/');
    }
  }, [navigate, visitDetails]);

  const getColorByStatusCode = (statusCode: string) => {
    switch (statusCode) {
      case Constants.INTERESTED:
        return '#43bf94';
      case Constants.NOT_INTERESTED:
        return '#ec686a';
      default:
        return '#3c3e41';
    }
  };

  function formatData(product: OpportunityProductRaw) {
    return {
      floor: formatField(product.property_unit?.floor?.code, 'floors'),
      levelsNumber: formatField(product.property_unit?.levels_number),
      name: formatField(product.property_unit?.name),
      status: formatField(
        product.property_unit?.availabilities?.[0]?.availability_status.code,
        'availabilityStatuses',
      ),
      residentialUseType: formatField(
        product.property_unit?.residential_details?.[0]?.residential_use_type
          .code,
        'residentialUseTypes',
      ),
      internalStatus: formatField(
        product.property?.used_status,
        'usedStatuses',
      ),
      cellarFlag: formatField(
        product?.property_unit?.residential_details?.[0]?.cellar_flag,
      ),
      terraceFlag: formatField(
        product?.property_unit?.residential_details?.[0]?.terrace_flag,
      ),
      balconyFlag: formatField(
        product?.property_unit?.residential_details?.[0]?.balcony_flag,
      ),
      communalGardenFlag: formatField(
        product?.property_unit?.residential_details?.[0]?.communal_garden_flag,
      ),
      roomsNumber: formatField(
        product.property_unit?.residential_details?.[0]?.rooms_number,
      ),
      bedroomsNumber: formatField(
        product.property_unit?.residential_details?.[0]?.bedrooms_number,
      ),
      bathroomsNumber: formatField(
        product.property_unit?.residential_details?.[0]?.bathroom_number,
      ),
      kitchenType: formatField(
        product.property_unit?.residential_details?.[0]?.kitchen_type?.code,
        'kitchenTypes',
      ),
      exposureType: formatField(
        product.property_unit?.residential_details?.[0]?.exposure_type?.code,
        'exposuretypes',
      ),
      laPositioningType: formatField(
        product.property_unit?.residential_details?.[0]?.la_positioning_type
          ?.code,
        'laPositioningTypes',
      ),
      fixturesMaterial: formatField(
        product.property_unit?.residential_details?.[0]?.fixtures_type?.code,
        'windowFixtures',
      ),
      securityDoorFlag: formatField(
        product.property_unit?.residential_details?.[0]?.security_door_flag,
      ),
      boxFlag: formatField(
        product.property_unit?.residential_details?.[0]?.box_flag,
      ),
      parkingSpaceFlag: formatField(
        product.property_unit?.residential_details?.[0]?.parking_space_flag,
      ),
      parkingSpaceMotoFlag: formatField(
        product.property_unit?.residential_details?.[0]
          ?.parking_space_moto_flag,
      ),
      electricChargeFlag: formatField(
        product.property_unit?.residential_details?.[0]?.electric_charge_flag,
      ),
      energyRating: formatField(
        product.property_unit?.residential_details?.[0]?.energy_rating?.code.toLocaleUpperCase(),
      ),
      energyRatingStatus: formatField(
        product.property_unit?.residential_details?.[0]?.energy_rating_status
          ?.code,
        'energyRatingStatuses',
      ),
      availableSurface: formatField(
        product.property_unit?.availabilities?.[0]?.available_surface,
        undefined,
        'm²',
      ),
      interestStatus: product.status?.code ?? Constants.MISSING,
      livingSurface: formatField(
        (product.property_unit?.surface_uses ?? []).find(
          (sU) => sU.surface_type.code === Constants.LIVING,
        )?.available_value,
        undefined,
        'm²',
      ),
      porchSurface: formatField(
        (product.property_unit?.surface_uses ?? []).find(
          (sU) => sU.surface_type.code === Constants.PORCH,
        )?.available_value,
        undefined,
        'm²',
      ),

      price: formatField(
        `${priceFormatter(
          product.property_unit?.availabilities?.[0].price_list.find(
            (pL) => pL.price_type.code === Constants.ASKING,
          )?.price,
        )} €`,
      ),
    };
  }

  const preparedData =
    opportunityProductsSortedByStatusAndFeedbacks.map(formatData);

  function formatField(
    value: string | boolean | undefined | number,
    translationPrefix?: string | undefined,
    uom?: string,
  ) {
    if (!value) {
      return (
        <span style={{ color: '#b1b1b1', fontWeight: 400 }}>
          {t('unavailableData')}
        </span>
      );
    } else if (typeof value === 'boolean') {
      return value ? (
        <i className="las la-check-circle text-success" />
      ) : (
        <i className="las la-times-circle text-danger" />
      );
    } else if (translationPrefix) {
      return tDom(`${translationPrefix}.${value}`);
    }
    return `${value}${uom ? ` ${uom}` : ''}`;
  }
  const {
    currentMarker,
    showModal,
    setShowModal,
    windowIsFlipped,
    setWindowIsFlipped,
    setCurrentMarker,
  } = useKeyplanResponsiveState();

  return (
    <div className="user-selections-page overflow-auto cover-container d-flex w-100 h-100 mx-auto flex-column">
      <Fragment>
        <Container fluid>
          <Row className="h-100 overflow-auto">
            <LeftColumn
              title={visitDetails?.name ?? ''}
              className={classes.stickyBackground}
            />
            {!isEmpty(
              visitDetails?.opportunityProducts?.filter(
                (oP) => oP.property_unit !== null,
              ),
            ) && (
              <Col
                xl={10}
                md={12}
                className={`${classes.galleryContainer} text-center mt-5`}
              >
                <Table
                  className={`${classes.selectionsTable} ${
                    isFullScreen
                      ? classes.selectionsTableStructure
                      : classes.selectionsTableStructureResponsive
                  }`}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          height: 200,
                        }}
                      />
                      {preparedData.map((data, idx) => (
                        <motion.td
                          key={idx}
                          style={{
                            borderTop: `3px solid ${getColorByStatusCode(
                              data.interestStatus,
                            )}`,
                          }}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{
                            duration: 2,
                            delay: idx / 4,
                            ease: [0, 0.71, 0.2, 1.01],
                          }}
                        >
                          <motion.img
                            src={`${
                              window.electron ? 'asset://' : ''
                            }image/planimetry/${data.name}.png`}
                            alt=""
                            onClick={() => {
                              if (isFullScreen || window.electron) {
                                setMarkerWindows((prev) => {
                                  if (
                                    prev.some(
                                      (gW) => gW === data.name.toString(),
                                    )
                                  ) {
                                    return prev;
                                  }

                                  return [...prev, data.name.toString()];
                                });
                              } else {
                                setShowModal(true);
                                setCurrentMarker({
                                  availability: '',
                                  availabilityColor: '',
                                  left: '',
                                  title: data.name as string,
                                  top: '',
                                });
                              }
                            }}
                            id={`table-image-${data.name}`}
                            style={{ marginTop: 10 }}
                          />
                          <div className={localClasses.feedbacksCount}>
                            <span
                              className={`${localClasses.feedbacksSpan} ${localClasses.positiveFeedbacksSpan}`}
                            >
                              <i className="las la-thumbs-up my-auto" />
                              {
                                (visitDetails?.opportunityProducts ?? []).find(
                                  (unit) =>
                                    unit.property_unit?.name === data.name,
                                )?.positive_feedbacks?.length
                              }
                            </span>
                            <span
                              className={`${localClasses.feedbacksSpan} ${localClasses.negativeFeedbacksSpan}`}
                            >
                              <i className="las la-thumbs-down my-auto" />
                              {
                                (visitDetails?.opportunityProducts ?? []).find(
                                  (unit) =>
                                    unit.property_unit?.name === data.name,
                                )?.negative_feedbacks?.length
                              }
                            </span>
                          </div>
                        </motion.td>
                      ))}

                      <motion.td
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                          duration: 2,
                          delay: preparedData.length / 4,
                          ease: [0, 0.71, 0.2, 1.01],
                        }}
                      >
                        <motion.div
                          className={classes.addFromKeyplanTableButtonContainer}
                          whileTap={{ scale: 0.95 }}
                          onClick={() => navigate('/keyplan-page')}
                        >
                          <div className={classes.addFromKeyplanTableButton}>
                            <i className="las la-plus-circle" />
                          </div>
                          <span>{t('addFromKeyplan')}</span>
                        </motion.div>
                      </motion.td>
                    </tr>
                    <tr>
                      <th className={classes.sectionTitle}>
                        <i className="las la-building me-1" />
                        {t('propertyUnit.generalInformation')}
                      </th>
                    </tr>
                    {generalInformationColumns.map((column) => (
                      <tr key={column.label}>
                        <td>{t(column.label)}</td>
                        {preparedData.map((data, key) => (
                          <AnimatedTd key={key} delay={key / 4}>
                            {data[column.key as keyof typeof data]}
                          </AnimatedTd>
                        ))}
                      </tr>
                    ))}

                    <tr>
                      <th className={classes.sectionTitle}>
                        <i className="las la-sliders-h me-1" />
                        {t('propertyUnit.pertinences')}
                      </th>
                    </tr>
                    {pertinencesFields.map((column) => (
                      <tr key={column.label}>
                        <td>{t(column.label)}</td>
                        {preparedData.map((data, key) => (
                          <AnimatedTd key={key} delay={key / 4}>
                            {data[column.key as keyof typeof data]}
                          </AnimatedTd>
                        ))}
                      </tr>
                    ))}

                    <tr>
                      <th className={classes.sectionTitle}>
                        <i className="las la-cubes me-1" />
                        {t('propertyUnit.composition')}
                      </th>
                    </tr>
                    {compositionFields.map((column) => (
                      <tr key={column.label}>
                        <td>{t(column.label)}</td>
                        {preparedData.map((data, key) => (
                          <AnimatedTd key={key} delay={key / 4}>
                            {data[column.key as keyof typeof data]}
                          </AnimatedTd>
                        ))}
                      </tr>
                    ))}

                    <tr>
                      <th className={classes.sectionTitle}>
                        <i className="las la-door-open me-1" />
                        {t('propertyUnit.usedStatus')}
                      </th>
                    </tr>
                    {internalStatusFields.map((column) => (
                      <tr key={column.label}>
                        <td>{t(column.label)}</td>
                        {preparedData.map((data, key) => (
                          <AnimatedTd key={key} delay={key / 4}>
                            {data[column.key as keyof typeof data]}
                          </AnimatedTd>
                        ))}
                      </tr>
                    ))}

                    <tr>
                      <th className={classes.sectionTitle}>
                        <i className="las la-list-alt me-1" />
                        {t('propertyUnit.features')}
                      </th>
                    </tr>
                    {internalFeaturesFields.map((column) => (
                      <tr key={column.label}>
                        <td>{t(column.label)}</td>
                        {preparedData.map((data, key) => (
                          <AnimatedTd key={key} delay={key / 4}>
                            {data[column.key as keyof typeof data]}
                          </AnimatedTd>
                        ))}
                      </tr>
                    ))}

                    <tr>
                      <th className={classes.sectionTitle}>
                        <i className="las la-parking me-1" />
                        {t('propertyUnit.parking')}
                      </th>
                    </tr>
                    {parkingFields.map((column) => (
                      <tr key={column.label}>
                        <td>{t(column.label)}</td>
                        {preparedData.map((data, key) => (
                          <AnimatedTd key={key} delay={key / 4}>
                            {data[column.key as keyof typeof data]}
                          </AnimatedTd>
                        ))}
                      </tr>
                    ))}

                    <tr>
                      <th className={classes.sectionTitle}>
                        <i className="las la-signal me-1" />
                        {t('propertyUnit.energyRating')}
                      </th>
                    </tr>
                    {energyRating.map((column) => (
                      <tr key={column.label}>
                        <td>{t(column.label)}</td>
                        {preparedData.map((data, key) => (
                          <AnimatedTd key={key} delay={key / 4}>
                            {data[column.key as keyof typeof data]}
                          </AnimatedTd>
                        ))}
                      </tr>
                    ))}

                    <tr>
                      <th className={classes.sectionTitle}>
                        <i className="las la-layer-group me-1" />
                        {t('propertyUnit.surfaces')}
                      </th>
                    </tr>
                    {surfacesFields.map((column) => (
                      <tr key={column.label}>
                        <td>{t(column.label)}</td>
                        {preparedData.map((data, key) => (
                          <AnimatedTd key={key} delay={key / 4}>
                            {data[column.key as keyof typeof data]}
                          </AnimatedTd>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            )}
          </Row>
        </Container>

        {(isFullScreen || window.electron) &&
          markerWindows.map((gW, idx) => {
            return (
              <MarkerWindow
                image={gW}
                key={gW}
                initialLeft={30 * idx + 595}
                initialTop={30 * idx + 25}
                idx={idx}
                gestureWindowHeight={gestureWindowHeight}
                availabilityData={availabilityData}
                opportunityProductDeletionInProgress={
                  opportunityProductDeletionInProgress
                }
                setOpportunityProductDeletionInProgress={
                  setOpportunityProductDeletionInProgress
                }
              />
            );
          })}
        {!isFullScreen && !window.electron && currentMarker && (
          <ModalResponsive
            currentMarker={currentMarker}
            availabilityData={availabilityData}
            showModal={showModal}
            setShowModal={setShowModal}
            setWindowIsFlipped={setWindowIsFlipped}
            windowIsFlipped={windowIsFlipped}
          />
        )}

        {isEmpty(
          visitDetails?.opportunityProducts?.filter(
            (oP) => oP.property_unit !== null,
          ),
        ) && (
          <div className={classes.noSelectedPropertiesWarning}>
            <div>
              <i
                className="las la-exclamation-triangle mb-3"
                style={{ fontSize: 35 }}
              />
            </div>
            <div className="mb-4">
              {t('noPropertiesWarning', { name: visitDetails?.name })}
            </div>

            <motion.div whileTap={{ scale: 0.95 }}>
              <Button
                className={`${classes.nunitoSans} ${localClasses.addPropertiesButton}`}
                onClick={() => navigate('/keyplan-page')}
              >
                <span className="d-flex align-items-center flex-wrap">
                  <i
                    className="las la-plus-circle me-1"
                    style={{ fontSize: 15 }}
                  />
                  {t('addFromKeyplanSection')}
                </span>
              </Button>
            </motion.div>
          </div>
        )}

        <div className={classes.background} />
        <MobileBottomContent />
      </Fragment>
    </div>
  );
};

const useLocalStyles = createUseStyles({
  userSelectionsPage: {},

  backgroundOnlyImage: {
    maxHeight: '90vh',
  },
  feedbacksCount: {
    marginTop: 15,
  },
  feedbacksSpan: { textAlign: 'center', padding: '5px 10px', borderRadius: 20 },
  positiveFeedbacksSpan: {
    backgroundColor: '#cff1e6',
    color: '#43bf93',
    marginRight: 5,
  },
  negativeFeedbacksSpan: {
    backgroundColor: '#fad7d8',
    color: '#e84444',
  },
  addPropertiesButton: {
    fontSize: 12,
  },
});
