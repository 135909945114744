import { ConnectionStatus } from 'authentication/hooks/UseDatahubPolling';
import { AppContext, AppContextType } from 'context';
import { motion } from 'framer-motion';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import { ResetFiltersButton } from 'pages/KeyPlan/components/propertySelector/ResetFiltersButton';
import { usePropertySelector } from 'pages/KeyPlan/hooks/usePropertySelector';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Fragment, useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { AvailabilityDataType } from 'types';
import { priceFormatter } from 'utils/priceFormatter';

type PropertySelectorFullProps = {
  setIsPropertySelectorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isPropertySelectorOpen: boolean;
  availabilityData: AvailabilityDataType;
  setHiddenUnits: React.Dispatch<React.SetStateAction<string[]>>;
  hiddenUnits: string[];
  setAreFiltersActive: React.Dispatch<React.SetStateAction<boolean>>;
  areFiltersActive: boolean;
  hideAvailabilities: boolean | undefined;
};

export const ANIMATION_EASE = [0.43, 0.13, 0.23, 0.96];

export const PropertySelectorFull: React.FC<PropertySelectorFullProps> = ({
  setIsPropertySelectorOpen,
  isPropertySelectorOpen,
  availabilityData,
  setHiddenUnits,
  hiddenUnits,
  setAreFiltersActive,
  areFiltersActive,
  hideAvailabilities,
}) => {
  const classes = useStyles();
  const { selectedLanguage, authenticationStatus } =
    useContext<AppContextType>(AppContext);

  const { t } = useCustomTranslation(selectedLanguage.code, 'translations');

  const {
    filteredUnits,
    activeButtons,
    setActiveButtons,
    selectedSurface,
    setSelectedSurface,
    minMaxSurfaces,
    selectedBudget,
    setSelectedBudget,
    minMaxPrice,
    setToggleAvailability,
    toggleAvailability,
    handleResetFilter,
    unitTypes,
    menuHeight,
    menuRef,
  } = usePropertySelector({
    availabilityData,
    setHiddenUnits,
    hiddenUnits,
    setAreFiltersActive,
  });

  return (
    <Fragment>
      <motion.div
        ref={menuRef}
        className={`${classes.filterSelector} pointer`}
        initial={{ translateY: menuHeight - 60 }}
        animate={{
          translateY: isPropertySelectorOpen ? 0 : menuHeight - 60,
          bottom: 0,
        }}
        transition={{
          duration: 0.5,
          ease: ANIMATION_EASE,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={`filter-selector-fullscreen ${classes.filterSelectorHeader}`}
        >
          <motion.span
            className={`las la-angle-up ${classes.arrowBtn}`}
            initial={{ rotate: 0 }}
            animate={{ rotate: isPropertySelectorOpen ? 180 : 0 }}
            transition={{ duration: 0.5, ease: ANIMATION_EASE }}
            onClick={() => {
              setIsPropertySelectorOpen((prev) => !prev);
            }}
          />
          <strong>{t('selectUnits')}</strong>
          {areFiltersActive && (
            <div className={classes.filterResults}>
              <span>
                {t('filteredUnits')}: <strong>{filteredUnits}</strong>
              </span>
              <ResetFiltersButton handleResetFilter={handleResetFilter} />
            </div>
          )}

          {!areFiltersActive && (
            <div className={classes.filterResults}>{t('noFiltersApplied')}</div>
          )}
        </div>

        <div className={classes.checkboxContainer}>
          <div className={classes.sectionTitle}>
            <i className={`las la-ruler-combined ${classes.mr1}`} />
            <strong>{t('selectUnitType')}</strong>
          </div>
          {unitTypes.map((type) => {
            const isButtonActive = activeButtons.includes(type.id);

            const animationProps = {
              backgroundColor: isButtonActive ? '#222222' : '#f0f0f3',
              color: isButtonActive ? '#fff' : '#212529',
              border: isButtonActive
                ? '1px solid #222222'
                : '1px solid #f0f0f3',
            };
            return (
              <motion.button
                className={classes.unitButton}
                key={type.id}
                onClick={() =>
                  setActiveButtons((prev) =>
                    prev.includes(type.id)
                      ? prev.filter((id) => id !== type.id)
                      : [...prev, type.id],
                  )
                }
                animate={animationProps}
                transition={{ duration: 0.3, ease: ANIMATION_EASE }}
              >
                {type.name}
              </motion.button>
            );
          })}
        </div>

        <hr className={classes.sectionSeparator} />
        {minMaxSurfaces[0] !== minMaxSurfaces[1] && (
          <Fragment>
            <div className={`${classes.checkboxContainer} ${classes.wrap}`}>
              <div className={classes.sectionTitle}>
                <i className={`las la-border-none ${classes.mr1}`} />
                <strong>{t('selectSurface')}</strong>
                <div className={classes.rangeContainer}>
                  <strong>
                    {selectedSurface[0]} - {selectedSurface[1]} m²
                  </strong>
                </div>
              </div>

              <div className={classes.sliderContainer}>
                <Slider
                  range
                  min={minMaxSurfaces[0]}
                  max={minMaxSurfaces[1]}
                  value={selectedSurface}
                  defaultValue={[0, 999999]}
                  onChange={(range) => {
                    setSelectedSurface(() => {
                      if (range instanceof Array) {
                        return range;
                      }
                      return [range, range];
                    });
                  }}
                />
              </div>
            </div>

            <hr className={classes.sectionSeparator} />
          </Fragment>
        )}

        {authenticationStatus === ConnectionStatus.userAuthenticated &&
          minMaxPrice[0] !== minMaxPrice[1] && (
            <Fragment>
              <div className={`${classes.checkboxContainer} ${classes.wrap}`}>
                <div className={classes.sectionTitle}>
                  <i className={`las la-money-bill-wave ${classes.mr1}`} />
                  <strong>{t('selectBudget')}</strong>
                  <div className={classes.rangeContainer}>
                    <strong>
                      {`${priceFormatter(selectedBudget[0])} - ${priceFormatter(
                        selectedBudget[1],
                      )} €`}
                    </strong>
                  </div>
                </div>

                <div className={classes.sliderContainer}>
                  <Slider
                    range
                    min={minMaxPrice[0]}
                    max={minMaxPrice[1]}
                    defaultValue={[0, 9999999999]}
                    value={selectedBudget}
                    onChange={(range) => {
                      setSelectedBudget(() => {
                        if (range instanceof Array) {
                          return range;
                        }
                        return [range, range];
                      });
                    }}
                  />
                </div>
              </div>

              <hr className={classes.sectionSeparator} />
            </Fragment>
          )}

        {!hideAvailabilities && (
          <div className={classes.checkboxContainer}>
            <div className={classes.sectionTitle}>
              <i className={`las la-check-circle ${classes.mr1}`} />
              <strong>{t('showUnavailablePropertiesQuestion')}</strong>
            </div>

            <div className={classes.checkboxCell}>
              <label className={classes.togglerWrapper}>
                <input
                  type="checkbox"
                  onClick={() => {
                    setToggleAvailability((prev) => !prev);
                  }}
                  checked={toggleAvailability}
                />
                <div className={classes.togglerSlider}>
                  <div className={classes.togglerKnob} />
                </div>
              </label>
              <span className={classes.toggleTitle}>
                {toggleAvailability
                  ? t('showAlsoUnAvailableUnits')
                  : t('showAvailableUnitsOnly')}
              </span>
            </div>
          </div>
        )}
      </motion.div>
    </Fragment>
  );
};

const useStyles = createUseStyles({
  filterSelector: {
    fontFamily: '"Nunito Sans", sans-serif',
    opacity: 0.9,
    zIndex: 998,
    position: 'absolute',
    backgroundColor: 'white',
    width: 750,
    height: 'max-content',
    paddingBottom: 40,
    left: 'calc(50% - 750px / 2)',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    display: 'flex',
    alignItems: 'center',
    paddingRight: 20,
    flexWrap: 'wrap',
    [`@media (max-width: 768px)`]: {
      width: '100%',
      left: 0,
    },
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },

  filterSelectorHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    height: '70px',
    marginBottom: '20px',
    marginRight: '40px',
  },
  separator: {
    margin: '0 0.2rem',
  },
  arrowBtn: {
    marginLeft: 25,
    marginRight: 15,
    fontSize: '1.3rem',
    cursor: 'pointer',
  },
  checkboxContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    '& slidecontainer': {
      width: '100%',
    },
    '& .rc-slider': {
      left: '18px',
      width: '88%',
    },
    '& .rc-slider-handle': {
      width: '25px',
      height: '25px',
      marginTop: '-10px',
      border: 'solid 1px #cccccc',
      boxShadow: '0 2px 6px rgba(153, 153, 153, 0.75)',
    },
    '& .rc-slider-track, .rc-slider-tracks': {
      backgroundColor: '#222222',
    },
    '& .rc-slider-handle-dragging': {
      borderColor: '#222222!important',
      boxShadow: '0 0 0 5px #222222!important',
    },
    '& .rc-slider-track-draggable': {
      cursor: 'grab',
    },
    '& .rc-slider-track-draggable:active': {
      cursor: 'grabbing',
    },
    '& slider': {
      WebkitAppearance: 'none',
      width: '100%',
      height: 15,
      borderRadius: 5,
      background: '#d3d3d3',
      outline: 'none',
      opacity: 0.7,
      WebkitTransition: '.2s',
      transition: 'opacity .2s',
      '&:hover': {
        opacity: 1,
      },
      '&::-webkit-slider-thumb': {
        WebkitAppearance: 'none',
        appearance: 'none',
        width: 25,
        height: 25,
        borderRadius: '50%',
        background: '#04AA6D',
        cursor: 'pointer',
      },
      '&::-moz-range-thumb': {
        width: 25,
        height: 25,
        borderRadius: '50%',
        background: '#222222',
        cursor: 'pointer',
      },
    },
  },
  checkboxCell: {
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  typeSelection: {
    width: '100%',
    flexWrap: 'nowrap',
    padding: '0 35px',
  },
  toggleTitle: {
    marginLeft: '20px',
    color: '#737373',
  },
  unitButton: {
    border: '2px solid #babac7',
    background: 'none',
    borderRadius: '50rem!important',
    padding: '8px 16px',
    margin: '2px',
    fontWeight: '700',
    cursor: 'pointer',
    color: '#babac7',
  },
  sectionSeparator: {
    backgroundColor: ' #f0f0f3',
    textAlign: 'center',
    margin: ' 12px 0',
    left: 0,
    right: 0,
    height: '2px',
    border: 'none',
    width: '96%',
    marginRight: '20px',
  },
  sectionTitle: { flex: ' 0 0 38%', paddingLeft: '10px' },
  subText: { color: '#babac7', fontSize: '11px', lineHeight: 1.5 },
  // Checkbox style
  togglerWrapper: {
    display: 'block',
    width: 45,
    height: 25,
    cursor: 'pointer',
    position: 'relative',
    '& input[type="checkbox"]': {
      display: 'none',
      '&:checked + $togglerSlider $togglerKnob': {
        left: 'calc(100% - 19px - 3px)',
      },
      '&:checked + $togglerSlider': {
        backgroundColor: '#44cc66',
      },
    },
    '& $togglerSlider': {
      backgroundColor: '#ccc',
      position: 'absolute',
      borderRadius: 100,
      top: 0,
      left: 0,
      width: '100%',
      height: '80%',
      transition: 'all 300ms ease',
    },
    '& $togglerKnob': {
      width: 'calc(20px + 6px)',
      height: 'calc(20px + 6px)',
      borderRadius: '50%',
      left: -3,
      top: -3,
      backgroundColor: '#fff',
      boxShadow: '0 2px 6px rgba(153, 153, 153, 0.75)',
      position: 'absolute',
      transition: 'all 300ms ease',
    },
  },
  togglerSlider: {},
  togglerKnob: {},
  wrap: {
    flexWrap: 'wrap',
  },
  rangeContainer: {
    fontSize: '12px',
    marginTop: '5px',
    marginLeft: '25px',
    color: '#919191',
    marginRight: '30px',
  },
  mr1: {
    marginRight: '.5em',
  },

  filterResults: {
    marginLeft: 'auto',
    paddingRight: 10,
    fontSize: 14,
  },
  resetFilterText: {
    cursor: 'pointer',
    userSelect: 'none',
    display: 'inline-block',
  },
  sliderContainer: {
    width: '60%',
  },
});
