import { motion } from 'framer-motion';
import { createUseStyles } from 'react-jss';

export const ResetFiltersButton = ({
  handleResetFilter,
}: {
  handleResetFilter: () => void;
}) => {
  const classes = useStyles();

  return (
    <motion.div
      className={classes.resetFilterText}
      whileTap={{ scale: 0.9 }}
      onClick={(e) => {
        handleResetFilter();
        e.stopPropagation();
      }}
    >
      <i className="las la-trash-alt" />
    </motion.div>
  );
};

const useStyles = createUseStyles({
  resetFilterText: {
    cursor: 'pointer',
    userSelect: 'none',
    display: 'inline-block',
    backgroundColor: '#F0F0F3',
    padding: '3px 9px',
    borderRadius: '50rem',
    marginLeft: 10,
    fontSize: 16,
  },
});
