/* eslint-disable no-restricted-imports */
import { Video } from '../pages/Video';
import { Webview } from '../pages/Webview';
import { KeyplanType, PageType } from '../types/configuration';
import { UseFetchUserDetails } from 'authentication/hooks/UseFetchUserDetails';
import { SideMenu } from 'components/SideMenu';
import { AppContext, AppContextType } from 'context';
import { AnimatePresence } from 'framer-motion';
import { BackgroundOnly } from 'pages/BackgroundOnly';
import { Flipbook } from 'pages/Flipbook';
import { Gallery } from 'pages/Gallery';
import { HomePage } from 'pages/HomePage';
import { IdlePage } from 'pages/IdlePage';
import { KeyPlan } from 'pages/KeyPlan';
import { UserSelectionsPage } from 'pages/UserSelection/UserSelectionsPage';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { createUseStyles } from 'react-jss';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const SCREENSAVER_TIMEOUT = 600000; // 10 minutes

export const AppRoutes: React.FC<{
  showAlert: boolean;
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
  isFullScreen: boolean;
}> = ({ setShowAlert, isFullScreen, showAlert }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [showSideMenu, setShowSideMenu] = useState(false);
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  const [showQrCode, setShowQrCode] = useState(false);
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);

  const { ready, configuration, updating } =
    useContext<AppContextType>(AppContext);

  const classes = useStyles();

  const handleOnIdle = useCallback(() => {
    if (!updating) {
      navigate('/idle-page');
    }
  }, [navigate, updating]);

  useIdleTimer({
    timeout: SCREENSAVER_TIMEOUT,
    onIdle: handleOnIdle,
  });

  const [serviceWorkerIsReady, setServiceWorkerIsReady] = useState(false);

  useEffect(() => {
    // If The app is built as a React app, the browser supports the service
    // worker api, but the service worker is not loaded, reload the page to make
    // sure that the service worker is available
    setTimeout(() => {
      if (!window.electron && 'serviceWorker' in navigator) {
        if (!navigator.serviceWorker?.controller) {
          window.location.reload();
        }
      }
      if (navigator.serviceWorker?.controller) {
        setServiceWorkerIsReady(true);
      }
    }, 100);

    if (window.electron) {
      setServiceWorkerIsReady(true);
    }
  }, []);

  const { userVisits, setUserVisits } = UseFetchUserDetails(
    configuration.general.demoDate,
  );

  if (
    updating ||
    !ready
    // TODO: Solve Safari reloading issue here
  ) {
    return (
      <div
        className={classes.emptyPage}
        style={{
          backgroundColor: '#242424',
          color: '#fff',
        }}
      >
        {updating && <h1>Updating...</h1>}
      </div>
    );
  }

  const pageComponents: PageType[] = configuration.pages;

  const pageComponentsMap = {
    Gallery,
    Flipbook,
    KeyPlan,
    BackgroundOnly,
    Video,
    Webview,
  };

  return (
    <AnimatePresence>
      {serviceWorkerIsReady && (
        <Fragment>
          <Routes location={location} key={location.pathname}>
            {pageComponents.map((page: any, id: number) => {
              const DynamicPage =
                pageComponentsMap[page.type as keyof typeof pageComponentsMap];

              return (
                <Route
                  path={page.path}
                  element={
                    <DynamicPage
                      {...page}
                      isFullScreen={isFullScreen}
                      showAlert={showAlert}
                      setShowAlert={setShowAlert}
                    />
                  }
                  key={id}
                />
              );
            })}

            <Route
              path="/"
              element={
                <HomePage
                  backgroundImage={configuration.home.backgroundImage!}
                />
              }
            />

            <Route
              path="/user-selections"
              element={
                <UserSelectionsPage
                  backgroundImage={configuration.general.backgroundImage!}
                  availabilityColors={
                    (
                      configuration.pages.find(
                        (p) => p.type === 'KeyPlan',
                      ) as KeyplanType
                    ).availabilityColors
                  }
                  data={
                    (
                      configuration.pages.find(
                        (p) => p.type === 'KeyPlan',
                      ) as KeyplanType
                    ).data
                  }
                  gestureWindowHeight={
                    (
                      configuration.pages.find(
                        (p) => p.type === 'KeyPlan',
                      ) as KeyplanType
                    ).gestureWindowHeight
                  }
                  isFullScreen={isFullScreen}
                />
              }
            />

            <Route
              path="/idle-page"
              element={
                <IdlePage
                  setUserVisits={setUserVisits}
                  setShowSideMenu={setShowSideMenu}
                />
              }
            />
          </Routes>
          <SideMenu
            key="sideMenu"
            setShowAlert={setShowAlert}
            timestamp={timestamp}
            userVisits={userVisits}
            setUserVisits={setUserVisits}
            showSideMenu={showSideMenu}
            setShowSideMenu={setShowSideMenu}
            showLanguageMenu={showLanguageMenu}
            setShowLanguageMenu={setShowLanguageMenu}
            isFullScreen={isFullScreen}
            setTimestamp={setTimestamp}
            showQrCode={showQrCode}
            setShowQrCode={setShowQrCode}
          />
        </Fragment>
      )}
    </AnimatePresence>
  );
};

const useStyles = createUseStyles({
  emptyPage: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
