import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import { Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';

type MobileBottomContentType = {
  children?: ReactNode;
};

export const MobileBottomContent: React.FC<MobileBottomContentType> = ({
  children,
}) => {
  return (
    <div
      className="d-xl-none mt-4"
      style={{ marginRight: 'auto', padding: '20px' }}
    >
      <Row>{children}</Row>
      <span />
      <MobileBottomBar />
    </div>
  );
};

const MobileBottomBar = () => {
  const navigate = useNavigate();

  const localClasses = useLocalStyles();

  return (
    <motion.div
      className={localClasses.bottomBar}
      initial={{ bottom: -200 }}
      animate={{ bottom: 10 }}
      transition={{
        duration: 0.8,
        delay: 0,
      }}
      id="mobileBottomBar"
    >
      <i
        className={`las la-undo ${localClasses.backButton}`}
        onClick={() => {
          navigate('/');
        }}
      />
    </motion.div>
  );
};

const useLocalStyles = createUseStyles({
  bottomBar: {
    color: 'white',
    textAlign: 'center',
    margin: 'auto',
    left: 0,
    right: 0,
    position: 'fixed',
    display: 'inline-block',
    bottom: 10,
    padding: 5,
  },
  backButton: {
    fontSize: 25,
    height: 45,
    width: 45,
    backgroundColor: '#3c3a39',
    borderRadius: '100%',
    padding: 10,
  },
});
