import { ConnectionStatus } from 'authentication/hooks/UseDatahubPolling';
import { AppContext, AppContextType } from 'context';
import { motion } from 'framer-motion';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import { ANIMATION_EASE } from 'pages/KeyPlan/components/propertySelector/PropertySelectorFull';
import { ResetFiltersButton } from 'pages/KeyPlan/components/propertySelector/ResetFiltersButton';
import { usePropertySelector } from 'pages/KeyPlan/hooks/usePropertySelector';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Fragment, useContext } from 'react';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { createUseStyles } from 'react-jss';
import { AvailabilityDataType } from 'types';
import { priceFormatter } from 'utils/priceFormatter';

type PropertySelectorResponsiveProps = {
  setIsPropertySelectorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isPropertySelectorOpen: boolean;
  availabilityData: AvailabilityDataType;
  setHiddenUnits: React.Dispatch<React.SetStateAction<string[]>>;
  hiddenUnits: string[];
  setAreFiltersActive: React.Dispatch<React.SetStateAction<boolean>>;
  areFiltersActive: boolean;
  hideAvailabilities: boolean | undefined;
};

export const PropertySelectorResponsive: React.FC<
  PropertySelectorResponsiveProps
> = ({
  setIsPropertySelectorOpen,
  isPropertySelectorOpen,
  availabilityData,
  setHiddenUnits,
  hiddenUnits,
  setAreFiltersActive,
  areFiltersActive,
  hideAvailabilities,
}) => {
  const classes = useStyles();
  const { selectedLanguage, authenticationStatus } =
    useContext<AppContextType>(AppContext);

  const { t } = useCustomTranslation(selectedLanguage.code, 'translations');

  const {
    filteredUnits,
    activeButtons,
    setActiveButtons,
    selectedSurface,
    setSelectedSurface,
    minMaxSurfaces,
    selectedBudget,
    setSelectedBudget,
    minMaxPrice,
    setToggleAvailability,
    toggleAvailability,
    handleResetFilter,
    unitTypes,
  } = usePropertySelector({
    availabilityData,
    setHiddenUnits,
    hiddenUnits,
    setAreFiltersActive,
  });

  return (
    <Fragment>
      <motion.div
        className={`${classes.filterSelector} pointer d-none d-xl-block`}
        initial={{ opacity: 1 }}
        animate={{
          opacity: isPropertySelectorOpen ? 0 : 1,
        }}
        transition={{
          duration: 0.5,
          ease: ANIMATION_EASE,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={classes.filterSelectorHeader}
          onClick={() => {
            setIsPropertySelectorOpen(true);
          }}
        >
          <motion.span
            className={`las la-angle-up ${classes.arrowBtn}`}
            initial={{ rotate: 0 }}
            animate={{ rotate: isPropertySelectorOpen ? 180 : 0 }}
            transition={{ duration: 0.5, ease: ANIMATION_EASE }}
          />
          <strong>{t('selectUnits')}</strong>
          {areFiltersActive && (
            <div className={classes.filterResultsText}>
              {t('filteredUnits')}: <strong>{filteredUnits}</strong>
              <ResetFiltersButton handleResetFilter={handleResetFilter} />
            </div>
          )}

          {!areFiltersActive && (
            <div className={classes.filterResultsText}>
              {t('noFiltersApplied')}
            </div>
          )}
        </div>
      </motion.div>

      {ReactDOM.createPortal(
        <i
          className={`las la-sliders-h ${classes.filterButton} ms-2`}
          onClick={() => setIsPropertySelectorOpen(true)}
        />,
        document.getElementById('mobileBottomBar') as Element,
      )}

      <Modal
        show={isPropertySelectorOpen}
        onHide={() => setIsPropertySelectorOpen(false)}
        centered
      >
        <ModalHeader closeButton className={classes.modalHeader}>
          <strong className="me-3">{t('selectUnits')}</strong>

          {areFiltersActive && (
            <div>
              <Fragment>
                <span className="ms-3 me-1">{t('filteredUnits')}:</span>
                <strong>{filteredUnits}</strong>
                <ResetFiltersButton handleResetFilter={handleResetFilter} />
              </Fragment>
            </div>
          )}

          {!areFiltersActive && (
            <div>
              <Fragment>
                <span className="ms-3 me-1" style={{ marginLeft: 'auto' }}>
                  {t('noFiltersApplied')}
                </span>
              </Fragment>
            </div>
          )}
        </ModalHeader>

        <ModalBody className={classes.propertySelectorModalBody}>
          <div className={classes.checkboxContainer}>
            <div className={classes.sectionTitle}>
              <i className={`las la-ruler-combined ${classes.mr1}`} />
              <strong>{t('selectUnitType')}</strong>
            </div>
            {unitTypes.map((type) => {
              const isButtonActive = activeButtons.includes(type.id);

              const animationProps = {
                backgroundColor: isButtonActive ? '#222222' : '#f0f0f3',
                color: isButtonActive ? '#fff' : '#212529',
                border: isButtonActive
                  ? '1px solid #222222'
                  : '1px solid #f0f0f3',
              };
              return (
                <motion.button
                  className={classes.unitButton}
                  key={type.id}
                  onClick={() =>
                    setActiveButtons((prev) =>
                      prev.includes(type.id)
                        ? prev.filter((id) => id !== type.id)
                        : [...prev, type.id],
                    )
                  }
                  animate={animationProps}
                  transition={{ duration: 0.3, ease: ANIMATION_EASE }}
                >
                  {type.name}
                </motion.button>
              );
            })}
          </div>

          <hr className={classes.sectionSeparator} />

          <div className={`${classes.checkboxContainer} ${classes.wrap}`}>
            {minMaxSurfaces[0] !== minMaxSurfaces[1] && (
              <Fragment>
                <div className={classes.sectionTitle}>
                  <i className={`las la-border-none ${classes.mr1}`} />
                  <strong>{t('selectSurface')}</strong>
                  <div className={classes.rangeContainer}>
                    <strong>
                      {selectedSurface[0]} - {selectedSurface[1]} m²
                    </strong>
                  </div>
                </div>

                <div>
                  <Slider
                    range
                    min={minMaxSurfaces[0]}
                    max={minMaxSurfaces[1]}
                    value={selectedSurface}
                    defaultValue={[0, 999999]}
                    onChange={(range) => {
                      setSelectedSurface(() => {
                        if (range instanceof Array) {
                          return range;
                        }
                        return [range, range];
                      });
                    }}
                  />
                </div>

                <hr className={classes.sectionSeparator} />
              </Fragment>
            )}

            {authenticationStatus === ConnectionStatus.userAuthenticated && (
              <Fragment>
                <div className={`${classes.checkboxContainer} ${classes.wrap}`}>
                  <div className={classes.sectionTitle}>
                    <i className={`las la-money-bill-wave ${classes.mr1}`} />
                    <strong>{t('selectBudget')}</strong>
                    <div className={classes.rangeContainer}>
                      <strong>
                        {`${priceFormatter(
                          selectedBudget[0],
                        )} - ${priceFormatter(selectedBudget[1])} €`}
                      </strong>
                    </div>
                  </div>

                  <div>
                    <Slider
                      range
                      min={minMaxPrice[0]}
                      max={minMaxPrice[1]}
                      defaultValue={[0, 9999999999]}
                      value={selectedBudget}
                      onChange={(range) => {
                        setSelectedBudget(() => {
                          if (range instanceof Array) {
                            return range;
                          }
                          return [range, range];
                        });
                      }}
                    />
                  </div>
                </div>

                <hr className={classes.sectionSeparator} />
              </Fragment>
            )}
          </div>

          {!hideAvailabilities && (
            <div className={classes.checkboxContainer}>
              <div className={classes.sectionTitle}>
                <i className={`las la-check-circle ${classes.mr1}`} />
                <strong>{t('showUnavailablePropertiesQuestion')}</strong>
              </div>

              <div className={classes.checkboxCell}>
                <label className={classes.togglerWrapper}>
                  <input
                    type="checkbox"
                    onChange={() => setToggleAvailability((prev) => !prev)}
                    checked={toggleAvailability}
                  />
                  <div className={classes.togglerSlider}>
                    <div className={classes.togglerKnob} />
                  </div>
                </label>
                <span className={classes.toggleTitle}>
                  {toggleAvailability
                    ? t('showAlsoUnAvailableUnits')
                    : t('showAvailableUnitsOnly')}
                </span>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

const useStyles = createUseStyles({
  filterSelector: {
    fontFamily: '"Nunito Sans", sans-serif',
    opacity: 0.9,
    zIndex: 998,
    position: 'fixed',
    backgroundColor: 'white',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    display: 'flex',
    alignItems: 'center',
    paddingRight: 20,
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    bottom: 0,
    width: 500,
  },

  filterSelectorHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    height: 50,
    marginRight: 40,
    cursor: 'pointer',
    color: '#212529',
  },
  separator: {
    margin: '0 0.2rem',
  },
  arrowBtn: {
    marginLeft: 25,
    marginRight: 15,
    fontSize: '1.3rem',
    cursor: 'pointer',
  },
  checkboxContainer: {
    alignItems: 'center',
    fontFamily: '"Nunito Sans", sans-serif',

    '& slidecontainer': {
      width: '100%',
    },
    '& .rc-slider': {
      left: '10px',
      width: '95%',
      margin: '12px 0',
    },
    '& .rc-slider-handle': {
      width: '25px',
      height: '25px',
      marginTop: '-10px',
      border: 'solid 1px #cccccc',
      boxShadow: '0 2px 6px rgba(153, 153, 153, 0.75)',
    },
    '& .rc-slider-track, .rc-slider-tracks': {
      backgroundColor: '#222222',
    },
    '& .rc-slider-handle-dragging': {
      borderColor: '#222222!important',
      boxShadow: '0 0 0 5px #222222!important',
    },
    '& .rc-slider-track-draggable': {
      cursor: 'grab',
    },
    '& .rc-slider-track-draggable:active': {
      cursor: 'grabbing',
    },
    '& slider': {
      WebkitAppearance: 'none',
      width: '100%',
      height: 15,
      borderRadius: 5,
      background: '#d3d3d3',
      outline: 'none',
      opacity: 0.7,
      WebkitTransition: '.2s',
      transition: 'opacity .2s',
      '&:hover': {
        opacity: 1,
      },
      '&::-webkit-slider-thumb': {
        WebkitAppearance: 'none',
        appearance: 'none',
        width: 25,
        height: 25,
        borderRadius: '50%',
        background: '#04AA6D',
        cursor: 'pointer',
      },
      '&::-moz-range-thumb': {
        width: 25,
        height: 25,
        borderRadius: '50%',
        background: '#222222',
        cursor: 'pointer',
      },
    },
  },
  checkboxCell: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0 10px -3px',
  },
  typeSelection: {
    width: '100%',
    flexWrap: 'nowrap',
    padding: '0 35px',
  },
  toggleTitle: {
    marginLeft: '20px',
    color: '#737373',
  },
  unitButton: {
    border: '2px solid #babac7',
    background: 'none',
    borderRadius: '50rem!important',
    padding: '6px 14px',
    margin: '4px 2px 2px 2px',
    fontWeight: '700',
    cursor: 'pointer',
    color: '#babac7',
  },
  sectionSeparator: {
    backgroundColor: ' #f0f0f3',
    textAlign: 'center',
    margin: ' 20px 0',
    left: 0,
    right: 0,
    height: '2px',
    border: 'none',
    width: '96%',
    marginRight: '20px',
  },
  sectionTitle: { flex: ' 0 0 38%', paddingLeft: '10px' },
  subText: { color: '#babac7', fontSize: '11px', lineHeight: 1.5 },
  // Checkbox style
  togglerWrapper: {
    left: 5,
    display: 'block',
    width: 45,
    height: 25,
    cursor: 'pointer',
    position: 'relative',
    '& input[type="checkbox"]': {
      display: 'none',
      '&:checked + $togglerSlider $togglerKnob': {
        left: 'calc(100% - 19px - 3px)',
      },
      '&:checked + $togglerSlider': {
        backgroundColor: '#44cc66',
      },
    },
    '& $togglerSlider': {
      backgroundColor: '#ccc',
      position: 'absolute',
      borderRadius: 100,
      top: 0,
      left: 0,
      width: '100%',
      height: '80%',
      transition: 'all 300ms ease',
    },
    '& $togglerKnob': {
      width: 'calc(20px + 6px)',
      height: 'calc(20px + 6px)',
      borderRadius: '50%',
      left: -3,
      top: -3,
      backgroundColor: '#fff',
      boxShadow: '0 2px 6px rgba(153, 153, 153, 0.75)',
      position: 'absolute',
      transition: 'all 300ms ease',
    },
  },
  togglerSlider: {},
  togglerKnob: {},
  wrap: {
    flexWrap: 'wrap',
  },
  rangeContainer: {
    fontSize: '13px',
    marginTop: '5px',
    marginLeft: '25px',
    color: '#919191',
    marginRight: '30px',
  },
  mr1: {
    marginRight: '.5em',
  },
  propertySelectorModalBody: {
    padding: '2rem!important',
  },

  modalHeader: {
    fontFamily: '"Nunito Sans", sans-serif',
    fontSize: 18,
    height: 60,
    '& .btn-close': {
      backgroundColor: 'white!important',
      borderRadius: '100%!important',
      bottom: 50,
      right: 24,
      top: -30,
      position: 'absolute',
    },
  },

  filterButton: {
    fontSize: 25,
    height: 45,
    width: 45,
    backgroundColor: '#3c3a39',
    borderRadius: '100%',
    padding: 10,
  },

  bottomBar: {
    color: 'white',
    textAlign: 'center',
    right: 260,
    position: 'fixed',
    bottom: 10,
    padding: 5,
    zIndex: 9999,
  },

  filterResultsText: { marginLeft: 'auto', paddingRight: 10, fontSize: 14 },
});
